.albumContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  margin: 20px;
}

.albumPhotos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.albumPhotoLarge {
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  flex: 3;

  /* background-color: #ff0000; */
}
.largePhotoImg {
  width: 70vh;
  margin: 10px;
}
.albumPhotoMedium {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  flex-wrap: wrap;
  /* background-color: blue; */
}
.photo {
  width: 100px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.photo:hover {
  transform: scale(1.05);
}

.selected {
  border: 2px solid #268b9d; /* رنگ قاب عکس انتخاب شده */
  border-radius: 10px;
}
