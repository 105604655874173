.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title1 {
  width: fit-content;
}
.underline {
  margin-top: 5px;
  height: 3px;
  background-color: #00a693;
  border-radius: 2px;
}

.banerBackground {
}
.baner {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #3cadd5; */
  /* border: 2px #3cadd5 solid; */
  width: 100%;
  border-radius: 10px;
}
.banerImg {
  width: 80%;
  max-width: 500px;
}

.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(246, 246, 246);
  width: 80%;
  margin: 20px 35px;
  padding: 15px;
  border-radius: 10px;
}
.title2 {
}

.pragraph1 {
  direction: rtl;
}

.section2 {
}
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 10px;
}
.card:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.24);
}
.cardImg {
  height: 150px;
  object-fit: contain;
  min-width: 260px;
}
.cardTitle {
  color: #00a693;
  cursor: pointer;
  margin: 0;
  align-self: center;
}
.cardTitleYear {
  margin: 0;
  font-size: medium;
  align-self: center;
}
.options {
  display: flex;
  align-items: center;
  justify-content: center;
}
.option {
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}
.optionIcon {
  color: #00a693;
  margin-bottom: 10px;
}

.costDetails {
  direction: rtl;
}
.item {
  display: flex;
  flex-direction: row;
  background-color: #eeeeee;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 5px;
}
.costTitle {
  font-size: 15px;
}
.cost {
  font-size: 15px;
  margin-right: 10px;
  color: #00a693;
}

/* .displayNone {
  display: none;
} */

/* .ArticleCard {
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  padding: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.24);
  }
} */
