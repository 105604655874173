.questionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
}
.questionsTitle {
  font-size: 25px;
  font-weight: 500;
}
.questions {
  display: flex;
  flex-wrap: wrap;
  direction: rtl;
  width: 85%;
  align-items: center;
  justify-content: center;
}
.question {
  font-size: 12px;
  margin: 10px;
  width: 500px;
  min-width: 250px;
  padding: 20px;
  background-color: rgb(236, 236, 236);
  border-radius: 10px;
  cursor: pointer;
}
.answer {
  font-size: 12px;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(245, 245, 245);
}
.terms {
  display: flex;
  direction: rtl;
  align-items: center;
  justify-content: center;
}
.term {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 50px 0px;
}
.termTitle {
  color: #00a693;
}
.termDescribtion {
}
