.contactusCard {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 10px;
  width: 80%;
  align-items: center;
}
.contactusCard:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.24);
}
.contactusCardTitle {
  color: #4dbbe3;
  cursor: pointer;
  margin: 0;
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
}

.contactusCardCenter {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  /* background-color: red; */
  width: 80%;
}

.contactusOptions {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  /* background-color: brown; */
}
.contactusOption {
  display: flex;
  flex-direction: row-reverse;
  /* background-color: #eeeeee; */
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  align-items: center;
}
.contactusOptionIcon {
  color: #4dbbe3;
  margin: 10px;
}
