/* App.css */
body {
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif, ma; */
  font-family: "main";
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

@font-face {
  font-family: "main";
  src: url("../public/fonts/iransansxv.woff2") format("woff2-variations");
  font-weight: 100 1000;
  font-display: fallback;
}
p {
  text-align: justify;
  line-height: 2;
}

/* div {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.leaflet-grab {
  cursor: url("/assets/icon/03.png") 25 25, auto;
}

h1 {
  color: #333;
}

p {
  color: #555;
}

select,
button {
  margin-top: 10px;
  padding: 8px;
  font-size: 16px;
}

button {
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
} */
