.footerContainer {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footerTop {
  display: flex;
  background-color: #00a693;
  justify-content: center;
}
.footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 70%;
  justify-content: center;
}
.footerRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  direction: rtl;
  color: #fff;

  margin: 50px 0px;
}
.footerCenter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: 50px 0px;
}
.footerCenterImg {
  width: 80px;
  cursor: pointer;
}
.footerLeft {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 50px 0px;
}
.footerLeftIcon {
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}

.footerBottomLine {
  display: flex;
  height: 50px;
  background-color: #035f54;
  align-items: center;
  justify-content: center;
}

/* #1c5664 */
